import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import "./LiveStyle.css";
import * as Backend from "../network/backend";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LoginScreen() {
    const navigate = useNavigate()

    const [channel, setChannel] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [selectedValue, setSelectedValue] = useState(1);
    const [selectedUnit, setSelectedUnit] = useState("");
    const [units, setUnits] = useState();

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleChangeUnit = (event) => {
        setSelectedUnit(event.target.value);
    };

    const getUnitsByLevel = async () => {
        setSelectedUnit("")
        await Backend.getUnits(selectedValue).then(unitsList => {
            setUnits(unitsList.data.data)
        })
    }

    useEffect(() => {
        getUnitsByLevel()
    }, [selectedValue])

    const cookie = new Cookies();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (cookie.get("tokenAdmin") === undefined) {
            await Backend.loginapi(email, password);
        }
        if (cookie.get("tokenAdmin") !== undefined) {
            const tokenAgora = await Backend.getTokenAgora({ channelName: channel })
            await Backend.createLive({ channelName: channel, unit_id: selectedUnit }).then(live => {
                if (live.data.message === "Already a channel") {
                    toast.error("اسم اللايف هذا موجود من قبل الرجاء تغييره", {
                        position: toast.POSITION.TOP_LEFT
                    })
                } else if (live.data.Success) {
                    navigate("/live", { state: { appid: tokenAgora.data.appid, channel: channel, agora_token: tokenAgora.data.token, uid: tokenAgora.data.uid, callingMove: true } })
                }
            })
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="join-room">
                    <select id="options" value={selectedValue} onChange={handleChange}>
                        <option value={1}>الفرقة الأولى - شريعة وقانون</option>
                        <option value={2}>الفرقة الثانية - شريعة وقانون</option>
                        <option value={3}>الفرقة الثالثة - شريعة وقانون</option>
                        <option value={4}>الفرقة الرابعة - شريعة وقانون</option>
                        <option value={11}>الفرقة الأولى - شريعة إسلامية</option>
                        <option value={22}>الفرقة الثانية - شريعة إسلامية</option>
                        <option value={33}>الفرقة الثالثة - شريعة إسلامية</option>
                        <option value={44}>الفرقة الرابعة - شريعة إسلامية</option>
                        <option value={111}>الفرقة الأولى - حقوق</option>
                        <option value={222}>الفرقة الثانية - حقوق</option>
                        <option value={333}>الفرقة الثالثة - حقوق</option>
                        <option value={444}>الفرقة الرابعة - حقوق</option>
                        <option value={5}>دبلوم قانون عام</option>
                        <option value={6}>دبلوم علوم اقتصادية ومالية</option>
                        <option value={7}>دبلوم قانون إدارى</option>
                        <option value={8}>دبلوم قانون خاص</option>
                        <option value={9}>دبلوم قانون جنائي</option>
                        <option value={10}>دبلوم شريعة إسلامية</option>
                    </select>
                    <select id="options" value={selectedUnit} onChange={handleChangeUnit}>
                        <option value="" disabled>اختر مادة</option>
                        <option value="free">لايف مجاني</option>
                        {units?.map((unit) => (
                            <option value={unit._id} key={unit._id}>{unit.name}</option>
                        ))}
                    </select>
                    <input
                        onChange={e => setChannel(e.target.value)}
                        placeholder="اسم اللايف"
                        value={channel}
                    />
                    {cookie.get("tokenAdmin") === undefined && (
                        <>
                            <input
                                onChange={e => setEmail(e.target.value)}
                                placeholder="البريد اللإلكتروني"
                                type="email"
                                name='email'
                                value={email}
                            />
                            <input
                                onChange={e => setPassword(e.target.value)}
                                placeholder="كلمة المرور"
                                type="password"
                                name='password'
                                value={password}
                            />
                        </>
                    )
                    }

                    {cookie.get("tokenAdmin") !== undefined ? (<button
                        className={`join-channel ${!channel || selectedUnit === "" ? "disabled" : ""}`}
                        disabled={!channel || selectedUnit === ""}
                        type="submit"
                    >
                        <span>ابدأ اللايف</span>
                    </button>) : (<button
                        className={`join-channel ${!channel || !email || !password || selectedUnit === "" ? "disabled" : ""}`}
                        disabled={!channel || !email || !password || selectedUnit === ""}
                        type="submit"
                    >
                        <span>ابدأ اللايف</span>
                    </button>)}
                </div>
            </form>
            <ToastContainer />
        </div>
    );

}

export default LoginScreen;
