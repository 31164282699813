import { Cookies } from "react-cookie";
import axios from "./axios";

const cookie = new Cookies();

export const getStudent = async (uids) => {
    return await axios.post('/student/get_students_in_live', uids);
};

export const getTokenAgora = async (channelName) => {
    const token = cookie.get("tokenAdmin")

    return await axios.post('/live/token', channelName, {
        headers: { Authorization: token },
    });
};

export const createLive = async (channelName) => {
    const token = cookie.get("tokenAdmin")

    return await axios.post('/live/create', channelName, {
        headers: { Authorization: token },
    });
};

export const endLive = async (channelName) => {
    const token = cookie.get("tokenAdmin")

    return await axios.put(`/live/end/${channelName}`, {}, {
        headers: { Authorization: token },
    });
};

export const loginapi = async (email, password) => {
    const res = await axios.post("/admin/login", { email, password });
    if (res.data.token) {
        await cookie.set("tokenAdmin", "Bearer " + res.data.token, { path: "/" });

        return res;
    } else return { success: false, message: res.data.message };
};

export const getUnits = async (level) => {
    const token = cookie.get("tokenAdmin")

    return await axios.get(`/unit/level/${level}`, {}, {
        headers: { Authorization: token },
    });
};