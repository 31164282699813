import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LiveScreen from './screens/LiveScreen';
import AgoraRTC, { AgoraRTCProvider } from 'agora-rtc-react';
import LoginScreen from './screens/LoginScreen';

function App() {

  const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

  return (
    <BrowserRouter className="App">
      <Routes>
        <Route path="/live" element={
          <AgoraRTCProvider client={client}>
            <LiveScreen />
          </AgoraRTCProvider>} />
        <Route path="/" element={<LoginScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
