import {
  LocalUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
  RemoteUser,
} from "agora-rtc-react";
import React, { useState, useEffect } from "react";
import * as Backend from "../network/backend"
import "./LiveStyle.css";
import { useLocation, useNavigate } from "react-router-dom";

function LiveScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const { appid, channel, agora_token, uid, callingMove } = location.state || {};

  const [calling, setCalling] = useState(callingMove);
  const isConnected = useIsConnected();

  useJoin({ appid: appid, channel: channel, token: agora_token ? agora_token : null, uid: uid }, calling);

  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);

  usePublish([localMicrophoneTrack, localCameraTrack]);

  const remoteUsers = useRemoteUsers();
  const [studentsList, setStudentsList] = useState([])
  const [uids, setUid] = useState([])

  const getStudents = async () => {
    setUid([])
    for (let i = 0; i < remoteUsers.length; i++) {
      setUid([...uids, remoteUsers[i].uid]);
    }

    await Backend.getStudent({ uids: uids }).then(students => {
      setStudentsList(students.data.students)
    })
  }

  useEffect(() => {
    if (isConnected) {
      getStudents()
      getStudents()
    }

  }, [remoteUsers, isConnected])

  return (
    <>
      <div className="room">
        {isConnected && (
          <>
            <div className="in-room">
              <div className="user-list">
                <div className="me">
                  <LocalUser
                    audioTrack={localMicrophoneTrack}
                    cameraOn={cameraOn}
                    micOn={micOn}
                    videoTrack={localCameraTrack}
                    playAudio={false}
                    cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                  >
                    <samp className="user-name">You</samp>
                  </LocalUser>
                </div>
              </div>
              <div style={{ padding: "10px" }}>
                <div>
                  عدد الطلاب الموجودين {remoteUsers.length}
                </div>
                <div className="users">
                  {remoteUsers.map((user) => (
                    <div key={user.uid}>
                      <RemoteUser user={user} playVideo={false}>
                        <samp className="user-name"></samp>
                      </RemoteUser>
                      <div>{studentsList?.find(item => item.uid === user.uid)?.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="control">
              <div className="left-control">
                <button className="btn" onClick={() => setMic(a => !a)}>
                  <i className={`i-microphone ${!micOn ? "off" : ""}`} />
                </button>
                <button className="btn" onClick={() => setCamera(a => !a)}>
                  <i className={`i-camera ${!cameraOn ? "off" : ""}`} />
                </button>
              </div>
              <button
                className={`btn btn-phone ${calling ? "btn-phone-active" : ""}`}
                onClick={() => {
                  setCalling(a => !a)
                  Backend.endLive(channel)
                  navigate('/')
                }}
              >
                {calling ? <i className="i-phone-hangup" /> : <i className="i-mdi-phone" />}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );

}

export default LiveScreen;
